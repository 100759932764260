* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 50px;
}

button:hover {
  cursor: pointer;
}

img {
  border: 2px solid rgb(31, 70, 243);
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.Header {
  width: 100%;
  display: flex;
  height: 50px;
}

.Link {
  text-decoration: none;
}

.Separator {
  border-left: 2px solid rgb(21, 6, 240);
}

.ShopContent {
  display: flex;
  flex-wrap: wrap;
  height: 800px;
  gap: 0.5%;
  justify-content: center;
}

.ShopList {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 20px;
  width: 400px;
  height: 800px;
  border: 5px solid #ebe708;
  border-radius: 5px;
}

.AssortmentContent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: center;
  overflow: auto;
  width: 1250px;
  height: 800px;
  border: 5px solid #041cf5;
  border-radius: 5px;
}

.Item {
  margin: 5px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  width: 360x;
  height: 380px;
  border: 5px solid #f8f400;
  border-radius: 2px;
}

.AssortmentItemImage {
  height: 250px;
  width: 250px;
  border: 0;
  border-bottom: 2px solid #0408fa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ItemInfo {
  position: absolute;
  bottom: 55px;
  padding-left: 7px;
  font-size: 25px;
}

.CartContent {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  gap: 0.5%;
  justify-content: center;
}

.UserInfo {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 770px;
  height: 750px;
  border: 5px solid rgb(251, 255, 12);
  border-radius: 5px;
}

.CartSection {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 855px;
  height: 800px;
  border-radius: 5px;
  position: relative;
}

.Cart {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 855px;
  gap: 15px;
  height: 550px;
  border: 5px solid rgb(2, 47, 248);
  border-radius: 5px;
  overflow: auto;
}

.EmptyCart {
  text-align: center;
  padding-top: 300px;
  font-size: 40px;
}

.CartItem {
  position: relative;
  padding: 15px;
  height: 330px;
  display: flex;
  flex-direction: row;
  border: 5px solid rgb(238, 255, 4);
  border-radius: 5px;
}

.CartItemDescription {
  padding-top: 10%;
  width: 476px;
  text-align: center;
}

.CartItemName {
  font-size: 25px;
  margin-bottom: 5px;
}

.CartItemCost {
  font-size: 25px;
  margin-bottom: 30px;
}

.CartItemQuantity {
  justify-content: center;
  display: flex;
  height: 60px;
}

.CartTotalSection {
  height: 200px;
  position: relative;
}

.CartTotalPrice {
  margin-top: 20px;
  height: 50px;
  font-size: 30px;
  position: absolute;
  bottom: 95px;
  left: 280px;
}
